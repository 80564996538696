var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"justify-end"},[_c('v-btn',{staticClass:"small-outset mr-15",attrs:{"rounded":"","color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'ManageItemCreate'})}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v(" mdi-plus ")]),_vm._v(" 新規アイテム作成 ")],1)],1),_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"outset welfare-card mx-10 my-5 px-5 py-5"},[_c('v-card-title',[_vm._v(" アイテム管理 "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"検索","single-line":"","hide-details":""},model:{value:(_vm.searchWord),callback:function ($$v) {_vm.searchWord=$$v},expression:"searchWord"}})],1),_c('v-data-table',{style:(_vm.styles.app),attrs:{"headers":_vm.itemTableHeaders,"items":_vm.items,"search":_vm.searchWord,"items-per-page":20,"no-results-text":"検索結果が見つかりません","no-data-text":"データがありません","footer-props":{
            showFirstLastPage: true,
            disableItemsPerPage: true,
            itemsPerPageOptions: [20, -1],
            itemsPerPageText: '表示件数'
          }},on:{"click:row":_vm.showDetail},scopedSlots:_vm._u([{key:"item.mainImgSrc",fn:function(ref){
          var item = ref.item;
return [(item.mainImgSrc)?_c('v-img',{attrs:{"src":item.mainImgSrc,"height":"100","width":"100"}}):_c('v-img',{attrs:{"height":"100","width":"100","src":require("@/assets/img/no_image.png")}})]}},{key:"item.price",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.price.toLocaleString())+" ")]}},{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+" - "+_vm._s(props.pageStop)+" (全"+_vm._s(props.itemsLength)+"件) ")]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }