import store from '@/store'
import Presenter from '@/components/pages/manage/item/list/Presenter'

export default {
  data: () => ({
    items: [],
    itemTableHeaders: [
      { text: 'メイン画像', value: 'mainImgSrc' },
      { text: 'アイテム名', value: 'name' },
      { text: '単価 (pt)', value: 'price' }
    ]
  }),

  async created () {
    this.items = await store.dispatch('item/getList')
  },

  render (h) {
    return h(Presenter, {
      props: {
        items: this.items,
        itemTableHeaders: this.itemTableHeaders
      },

      on: {
        showDetail (presenter, itemUuid) {
          presenter.$router.push({ name: 'ManageItemDetail', params: { uuid: itemUuid }})
        }
      }
    })
  }
}
