<template>
  <div>
    <v-row class="justify-end">
      <v-btn
        rounded
        color="primary"
        class="small-outset mr-15"
        @click="$router.push({ name: 'ManageItemCreate'})"
      >
        <v-icon class="mr-1">
          mdi-plus
        </v-icon>
        新規アイテム作成
      </v-btn>
    </v-row>

    <v-row>
      <v-col>
        <v-card class="outset welfare-card mx-10 my-5 px-5 py-5">
          <v-card-title>
            アイテム管理
            <v-spacer />
            <v-text-field
              v-model="searchWord"
              append-icon="mdi-magnify"
              label="検索"
              single-line
              hide-details
            />
          </v-card-title>
          <v-data-table
            :headers="itemTableHeaders"
            :items="items"
            :search="searchWord"
            :style="styles.app"
            :items-per-page="20"
            no-results-text="検索結果が見つかりません"
            no-data-text="データがありません"
            :footer-props="{
              showFirstLastPage: true,
              disableItemsPerPage: true,
              itemsPerPageOptions: [20, -1],
              itemsPerPageText: '表示件数'
            }"
            @click:row="showDetail"
          >
            <template v-slot:item.mainImgSrc="{ item }">
              <v-img
                v-if="item.mainImgSrc"
                :src="item.mainImgSrc"
                height="100"
                width="100"
              />
              <v-img
                v-else
                height="100"
                width="100"
                src="@/assets/img/no_image.png"
              />
            </template>
            <template v-slot:item.price="{ item }">
              {{ item.price.toLocaleString() }}
            </template>
            <template v-slot:footer.page-text="props">
              {{ props.pageStart }} - {{ props.pageStop }} (全{{ props.itemsLength }}件)
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CommonMixin from '@/mixins/CommonMixin'

export default {
  mixins: [
    CommonMixin
  ],

  props: {
    itemTableHeaders: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    searchWord: ''
  }),

  methods: {
    showDetail (row) {
      this.$emit('showDetail', this, row.uuid)
    }
  }
}
</script>
